const slashInterpolation = (id) => (
  id === '' ? id : `${id}/` // add slash at the end if "id" param exists
);

const endpointsMapping = {
  // AUTH
  activate: () => ['auth/activate/'],
  deactivate: () => ['auth/deactivate/'],
  delete: () => ['auth/delete/'],
  login: () => ['auth/login/'],
  login_on_legacy_platform: () => ['auth/login_on_legacy_platform/'],
  logout: () => ['auth/logout/'],
  logout_on_legacy_platform: () => ['auth/logout_on_legacy_platform/'],
  register: () => ['auth/register/'],
  request_organisation: () => ['/auth/request_organisation/'],
  resend_activation: () => ['auth/resend_activation_email/'],

  // TWO FACTOR
  two_factor_authenticate: () => ['two-factor/authenticate/'],
  two_factor_create_backup_tokens: () => ['two-factor/create_backup_tokens/'],
  two_factor_get_device: () => ['two-factor/get_device/'],

  // CHAT
  attachments: (chatId = '') => [`chats/${chatId}/attachments/`, true],
  chats: () => ['chats/', true],
  messages: (chatId = '') => [`chats/${chatId}/messages/`, true],

  // CARE GIVERS
  care_givers_limited_access: (carePlanId = '') => [`care-plans/${carePlanId}/care-giver-limited-access/`, true],

  // CARE TAKERS
  care_takers: (careTakersId = '') => [`care-takers/${slashInterpolation(careTakersId)}`, true],
  care_takers_activities: (careTakersId = '') => [`care-takers/${careTakersId}/activities/`, true],
  care_takers_report: (careTakersId = '') => [`care-takers/${careTakersId}/report/`, true],

  // CARE PLAN
  care_plan_patient: (id = '') => [`patients/${id}/care-plans/`, true],

  // COACHING
  coaching: (id = '') => [`patients/${id}/coaching/`, true],

  // CONNECTORS
  validate_auth_token: () => ['/connectors/validate/'],
  validate_sso_token: () => ['/connectors/validate_sso/'],

  // CONTACTS
  contacts: () => ['contacts/', true],

  // ENROLLMENTS
  enrollments: (enrollmentId = '') => [`enrollments/${slashInterpolation(enrollmentId)}`, true],
  enrollments_analytics: (enrollmentId = '') => [`enrollments/${enrollmentId}/analytics/`, true],
  enrollments_care_givers: (enrollmentId = '', carePlanId = '') => [`enrollments/${enrollmentId}/care-plans/${carePlanId}/care-givers/`, true],
  enrollments_care_plans: (enrollmentId = '', carePlanId = '') => [`enrollments/${enrollmentId}/care-plans/${slashInterpolation(carePlanId)}`, true],
  enrollments_care_takers: (enrollmentId = '', careTakerId = '') => [`enrollments/${enrollmentId}/care-takers/${slashInterpolation(careTakerId)}`, true],
  enrollments_care_takers_activities: (enrollmentId = '', careTakerId = '', activityId = '') => [`enrollments/${enrollmentId}/care-takers/${careTakerId}/activities/${slashInterpolation(activityId)}`, true],
  enrollments_care_takers_assignments: (enrollmentId = '', careTakerId = '') => [`enrollments/${enrollmentId}/care-takers/${careTakerId}/assignments/`, true],
  enrollments_care_takers_resend_invitation: (enrollmentId = '', careTakerId = '') => [`enrollments/${enrollmentId}/care-takers/${careTakerId}/resend_invitation/`, true],
  enrollments_care_takers_archive: (enrollmentId = '', careTakerId = '') => [`enrollments/${enrollmentId}/care-takers/${careTakerId}/archive/`, true],
  enrollments_report: (enrollmentId = '', carePlanId = '') => [`/enrollments/${enrollmentId}/care-takers/${carePlanId}/report/`, true],
  enrollments_selected: (enrollmentId = '') => [`enrollments/${enrollmentId}/selected/`, true],
  enrollments_questionnaire_assignment: (enrollmentId = '', participantId = '', questionnaireAssignmentId = '') => [`/enrollments/${enrollmentId}/care-takers/${participantId}/questionnaire-assignments/${slashInterpolation(questionnaireAssignmentId)}`, true],
  enrollments_treatment_assignment: (enrollmentId = '', participantId = '') => [`/enrollments/${enrollmentId}/care-takers/${participantId}/treatment-assignments/`, true],

  // FEATURE FLAG
  feature_flags: () => ['feature-flags/features/', true],

  // INVITATIONS
  invitation_token: (id = '') => [`/invitations/${id}/status/`],
  invitation_decline: (id = '') => [`/invitations/${id}/decline/`],

  // KRACHTPLAN
  krachtplan_categories: () => ['krachtplan/categories/', true],
  krachtplan_permission: () => ['krachtplan/permissions/grant/', true],
  krachtplan_registrations: (carePlanId = '') => [`krachtplan/care-plans/${carePlanId}/registrations/`, true],
  krachtplan_helping_actions: (carePlanId = '') => [`/krachtplan/care-plans/${carePlanId}/helping-actions/`, true],
  krachtplan_habits: (carePlanId = '') => [`/krachtplan/care-plans/${carePlanId}/habits/`, true],

  // MONITOR
  monitor_permissions_accept: (requestId = '') => [`/monitors-permission/${requestId}/accept/`, true],
  monitor_permissions_decline: (requestId = '') => [`/monitors-permission/${requestId}/decline/`, true],
  monitor_permissions_requester: (patientId = '', professionalId = '') => [`patients/${patientId}/monitors-permission/requester:${professionalId}`, true],
  monitor_permissions: (patientId = '') => [`patients/${patientId}/monitors-permission/`, true],
  monitor_records: (monitorId = '', recordId = '') => [`monitors/${monitorId}/records/${slashInterpolation(recordId)}`, true],
  monitors: (patientId = '', monitorId = '') => [`patients/${patientId}/monitors/${slashInterpolation(monitorId)}`, true],

  // NETWORK
  network: () => ['network/', true],

  // NOTIFICATIONS
  notifications: () => ['notifications/', true],

  // ORGANISATIONS
  organisations: (id = '') => [`organisations/${slashInterpolation(id)}`, true],
  organisations_enrollments: (id = '') => [`organisations/${id}/enrollments/`, true],
  organisations_libraries: (organisationId = '', treatmentId = '', libraryId = '') => [`organisations/${organisationId}/treatments/${treatmentId}/libraries/${slashInterpolation(libraryId)}`, true],
  organisations_payment_references: (organisationId = '', languageCode = '') => [`organisations/${organisationId}/payment-references/?lang=${languageCode}`, true],
  organisations_questionnaires: (id = '') => [`organisations/${id}/questionnaires/`, true],
  organisations_slug_care_plans: (organisationSlug = '') => [`organisations/slug:${organisationSlug}/care-plans`],
  organisations_slug: (organisationSlug = '') => [`organisations/slug:${organisationSlug}`],
  organisations_treatments_info_popups: (organisationId = '', treatmentId = '', infoPopUpId = '') => [`organisations/${organisationId}/treatments/${treatmentId}/info-popups/${infoPopUpId}/`, true],
  organisations_treatments_patient_information: (treatmentId = '') => [`treatments/${treatmentId}/patient-information/`, true],
  organisations_treatments_professional_information_pages: (organisationId = '', treatmentId = '', pageId = '') => [`organisations/${organisationId}/treatments/${treatmentId}/professional-information-pages/${slashInterpolation(pageId)}`, true],
  organisations_treatments_professional_information: (organisationId = '', treatmentId = '') => [`organisations/${organisationId}/treatments/${treatmentId}/professional-information/`, true],
  organisations_treatments_steps: (organisationId = '', treatmentId = '', stepId = '') => [`organisations/${organisationId}/treatments/${treatmentId}/steps/${slashInterpolation(stepId)}`, true],
  organisations_treatments: (organisationId = '', treatmentId = '') => [`organisations/${organisationId}/treatments/${slashInterpolation(treatmentId)}`, true],

  // ORGANISATION ADMIN
  organisation_admin_treatments: (organisationId = '') => [`organisations/${organisationId}/admin/treatments/`, true],
  organisation_admin_treatment_assignments: (organisationId = '', treatmentAssignmentId = '') => [`organisations/${organisationId}/admin/treatment-assignments/${slashInterpolation(treatmentAssignmentId)}`, true],
  organisation_admin_questionnaires: (organisationId = '') => [`organisations/${organisationId}/admin/questionnaires/`, true],
  organisation_admin_questionnaires_assignments: (organisationId = '', questionnaireAssignmentId = '') => [`organisations/${organisationId}/admin/questionnaire-assignments/${slashInterpolation(questionnaireAssignmentId)}`, true],
  organisation_admin_questionnaires_assignments_send_reminder: (organisationId = '', questionnaireAssignmentId = '') => [`organisations/${organisationId}/admin/questionnaire-assignments/${questionnaireAssignmentId}/send_reminder/`, true],
  organisation_admin_care_plans: (organisationId = '', carePlanId = '') => [`organisations/${organisationId}/admin/care-plans/${slashInterpolation(carePlanId)}`, true],
  organisation_admin_care_takers: (organisationId = '', careTakerId = '') => [`organisations/${organisationId}/admin/care-takers/${slashInterpolation(careTakerId)}`, true],
  organisation_admin_care_takers_assignments: (organisationId = '', careTakerId = '') => [`organisations/${organisationId}/admin/care-takers/${careTakerId}/assignments/`, true],
  organisation_admin_care_takers_archive: (organisationId = '', careTakerId = '') => [`organisations/${organisationId}/admin/care-takers/${careTakerId}/archive/`, true],
  organisation_admin_care_takers_resend_invitation: (organisationId = '', careTakerId = '') => [`organisations/${organisationId}/admin/care-takers/${careTakerId}/resend_invitation/`, true],

  // PARTICIPATIONS
  participations: (id = '') => [`participations/${slashInterpolation(id)}`, true],
  participation_accept: (id = '') => [`participations/${id}/accept/`, true],
  participation_decline: (id = '') => [`participations/${id}/decline/`, true],

  // PAYMENTS
  payments: (token = '') => [`payments/${slashInterpolation(token)}`, true],

  // REFINER SURVEY
  refiner_survey: (surveyId = '') => [`treatment-assignment-survey-answers/${slashInterpolation(surveyId)}`, true],

  // PROJECT VERSION
  project_version: () => ['project-version/latest/'],

  // QUESTIONNAIRE ASSIGNMENT
  questionnaire_assignments: (id = '') => [`questionnaire-assignments/${slashInterpolation(id)}`, true],
  questionnaire_assignments_complete: (id = '') => [`/questionnaire-assignments/${id}/complete/`, true],
  questionnaire_assignments_evaluation: (id = '') => [`/questionnaire-assignments/${id}/evaluation/`, true],
  questionnaire_assignments_send_reminder: (id = '') => [`/questionnaire-assignments/${id}/send_reminder/`, true],
  questionnaire_informant_assignment: (id = '') => [`informants/questionnaire-assignments/${slashInterpolation(id)}`],
  questionnaire_informant_assignment_complete: (id = '') => [`informants/questionnaire-assignments/${id}/complete/`],
  questionnaire_informant_security_code: () => ['informant-questionnaires-access/security_code/'],
  questionnaire_informant_validate_code: () => ['informant-questionnaires-access/validate_code/'],

  // SOCIAL ENVIRONMENT
  social_environment_treatment: (socialEnvironmentTreatmentId = '') => [`/social-environment-treatments/${slashInterpolation(socialEnvironmentTreatmentId)}`],
  social_environment_treatment_step: (socialEnvironmentTreatmentId = '', socialEnvironmentTreatmentStepId = '') => [`/social-environment-treatments/${socialEnvironmentTreatmentId}/steps/${slashInterpolation(socialEnvironmentTreatmentStepId)}`],
  social_environment_treatment_info_popups: (socialEnvironmentTreatmentId = '', socialEnvironmentTreatmentPopUpId = '') => [`/social-environment-treatments/${socialEnvironmentTreatmentId}/info-popups/${slashInterpolation(socialEnvironmentTreatmentPopUpId)}`],
  social_environment_treatment_libraries: (socialEnvironmentTreatmentId = '', socialEnvironmentTreatmentLibraryId = '') => [`/social-environment-treatments/${socialEnvironmentTreatmentId}/libraries/${slashInterpolation(socialEnvironmentTreatmentLibraryId)}`],

  // TREATMENT ASSIGNMENTS
  treatment_assignments: (id = '') => [`treatment-assignments/${slashInterpolation(id)}`, true],
  treatment_assignments_answers: (treatmentAssignmentId = '', answerId = '') => [`treatment-assignments/${treatmentAssignmentId}/answers/${slashInterpolation(answerId)}`, true],
  treatment_assignments_goals: (treatmentAssignmentId = '', goalId = '') => [`treatment-assignments/${treatmentAssignmentId}/goals/${slashInterpolation(goalId)}`, true],
  treatment_assignments_goal_scores: (treatmentAssignmentId = '', goalId = '', scoreId = '') => [`treatment-assignments/${treatmentAssignmentId}/goals/${goalId}/scores/${slashInterpolation(scoreId)}`, true],
  treatment_assignments_payment: (id = '') => [`treatment-assignments/${id}/pay/`, true],
  treatment_assignments_questionnaire: (treatmentAssignmentId = '', id = '') => [`treatment-assignments/${treatmentAssignmentId}/questionnaire-element/${slashInterpolation(id)}`, true],
  treatment_assignments_questionnaire_complete: (treatmentAssignmentId = '', id = '') => [`treatment-assignments/${treatmentAssignmentId}/questionnaire-element/${id}/complete/`, true],
  treatment_assignments_report: (id = '') => [`/treatment-assignments/${id}/report/`, true],
  treatment_assignments_steps: (treatmentAssignmentId = '', stepId = '') => [`treatment-assignments/${treatmentAssignmentId}/steps/${slashInterpolation(stepId)}`, true],
  treatment_assignments_steps_complete: (treatmentAssignmentId = '', stepId = '') => [`treatment-assignments/${treatmentAssignmentId}/steps/${stepId}/complete/`, true],

  // PROGRAM ASSIGNMENTS
  program_assignment_involvement: () => ['program-assignment-involvements/', true],

  // PARTICIPANTS
  participant: (participantId = '') => [`participants/${slashInterpolation(participantId)}`, true],

  // USERS
  accept_user_terms: () => ['users/accept_user_terms/', true],
  change_email: () => ['users/change_email/', true],
  change_password: () => ['users/change_password/', true],
  email: () => ['users/email/', true],
  forgot_password: () => ['users/password_recovery/'],
  set_password: () => ['users/set_password/', true],
  settings: () => ['users/settings/', true],

  // REPORTS (PDF)
  reports: (uuid = '') => [`reports/${slashInterpolation(uuid)}`],

  // VIDEO CALL WESEEDO
  video_call_external_auto_login: (enrollmentId = '') => [`enrollments/${enrollmentId}/external-video-calls/auto_login/`, true],
  video_call_external_meetings: (enrollmentId = '') => [`enrollments/${enrollmentId}/external-video-calls/meetings/`, true],
};

export default (key, ...args) => {
  const [url, auth_required] = endpointsMapping[key](...args);
  return [url, { headers: { auth_required } }];
};
